import React, { useState } from "react";
import styles from "./Booking.module.scss";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

//TODO: highlight location when hovered
//TODO: calander colors

function Booking() {
  let navigate = useNavigate();
  const [value, onChange] = useState(new Date());

  return (
    <div className={styles.booking}>
      <div className={styles.header}>
        <h2>Book Your Appointment</h2>
        <div className={styles.CalendarWrapper}>
          <Calendar onChange={onChange} value={value} />
        </div>
        <div clasName={styles.bookingList}>
          <Card className={styles.location}>
            <div className={styles.locationName}>
              Bailey Nelson - Robson Street
            </div>
            <div className={styles.locationTimes}>
              <div className={styles.time}>10:30am</div>
              <div className={styles.time}>12:30pm</div>
              <div className={styles.time} onClick={() => navigate("/confirm")}>
                4:00pm
              </div>
            </div>
          </Card>
          <Card className={styles.location}>
            <div className={styles.locationName}>
              Bailey Nelson - West 4th Ave
            </div>
            <div className={styles.locationTimes}>
              <div className={styles.time}>9:30am</div>
              <div className={styles.time}>1:30pm</div>
              <div className={styles.time} onClick={() => navigate("/confirm")}>
                4:00pm
              </div>
            </div>
          </Card>
          <Card className={styles.location}>
            <div className={styles.locationName}>
              Bailey Nelson - Main Street
            </div>
            <div className={styles.locationTimes}>
              <div className={styles.time}>11:30am</div>
              <div className={styles.time}>2:30pm</div>
              <div className={styles.time} onClick={() => navigate("/confirm")}>
                4:00pm
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Booking;
