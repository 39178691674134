import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import styles from "./Service.module.scss";

function Service() {
  let navigate = useNavigate();
  let location = useLocation();
  const [zipcode, setZipcode] = useState("V7J 3G6");

  useEffect(() => {
    if (!location.state) {
      navigate("/benefits");
    }
  }, []);

  return (
    <div className={styles.services}>
      <div className={styles.benefits}>
        <div className={styles.header}>
          <h1>{location.state?.benefit}</h1>
          <div className={styles.remaining}>
            <p>$250/$1000</p>
            <p>remaining $750</p>
            <LinearProgress variant="determinate" value={25} />
          </div>
        </div>

        <div className={styles.list}>
          <h4 className={styles.overviewText}>Select Service</h4>
          <Card
            sx={{ minWidth: 275 }}
            className={styles.benefitsCard}
            onClick={() => navigate("/bookings")}
          >
            <h2>Eye Exam</h2>
          </Card>
          <Card
            sx={{ minWidth: 275 }}
            className={styles.benefitsCard}
            onClick={() => navigate("/bookings")}
          >
            <h2>Corrective Eyewear</h2>
          </Card>
          <Card
            sx={{ minWidth: 275 }}
            className={styles.benefitsCard}
            onClick={() => navigate("/bookings")}
          >
            <h2>Eyeglass Repair</h2>
          </Card>

          <div className={styles.loadmore}>Load More...</div>

          <div className={styles.zipcode}>
            <h4>Postal Code</h4>
            <Input
              defaultValue=""
              placeholder="000-000"
              value={zipcode}
              onChange={(e) => {
                setZipcode(e.target.value);
              }}
              className={styles.input}
            />
            <Button variant="contained" className={styles.button}>
              go
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
