import React from "react";
import styles from "./Benefits.module.scss";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";

import Sunlife from "../../images/sunlife.png";
import ConorImg from "../../images/conor.png";
//TODO: add barcode and policy number card

function Benefits() {
  let navigate = useNavigate();

  return (
    <div className={styles.benefits}>
      <div className={styles.header}>
        <h1>Conor Power,</h1>
        <img src={ConorImg} className={styles.conor}></img>
      </div>

      <Card className={styles.policyCard}>
        <img className={styles.sunlifeLogo} src={Sunlife} />
        <p>Policy Number: 77402</p>
        <p>Identification Number: 100190</p>
      </Card>

      <div className={styles.list}>
        <h4 className={styles.overviewText}>Overview</h4>
        <Card sx={{ minWidth: 275 }} className={styles.benefitsCard}>
          <h2>Dental</h2>
          <div className={styles.remaining}>
            <p>$600/$2500</p>
            <p>remaining $1900</p>
            <LinearProgress variant="determinate" value={25} />
          </div>
        </Card>
        <Card
          sx={{ minWidth: 275 }}
          className={styles.benefitsCard}
          onClick={() =>
            navigate("/services", {
              state: { id: 1, benefit: "Optometry" },
            })
          }
        >
          <h2>Optometry</h2>
          <div className={styles.remaining}>
            <p>$250/$1000</p>
            <p>remaining $750</p>
            <LinearProgress variant="determinate" value={25} />
          </div>
        </Card>
        <Card sx={{ minWidth: 275 }} className={styles.benefitsCard}>
          <h2>Physiotherapy</h2>
          <div className={styles.remaining}>
            <p>$0/$600</p>
            <p>remaining $600</p>
            <LinearProgress variant="determinate" value={0} />
          </div>
        </Card>
        <Card sx={{ minWidth: 275 }} className={styles.benefitsCard}>
          <h2>Counselling</h2>
          <div className={styles.remaining}>
            <p>$600/$800</p>
            <p>remaining $200</p>
            <LinearProgress variant="determinate" value={75} />
          </div>
        </Card>
        <Card sx={{ minWidth: 275 }} className={styles.benefitsCard}>
          <h2>Massage</h2>
          <div className={styles.remaining}>
            <p>$120/$400</p>
            <p>remaining $280</p>
            <LinearProgress variant="determinate" value={40} />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Benefits;
