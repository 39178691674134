import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "./theme";
import Main from "./views/Main";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <div className="App">
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
