import React, { useState } from "react";
import styles from "./ConfirmBooking.module.scss";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import "react-calendar/dist/Calendar.css";

function ConfirmBooking() {
  let navigate = useNavigate();
  const [submitted, setSubmit] = useState();

  const submit = () => {
    setSubmit(true);
  };

  return (
    <div className={styles.confirmBooking}>
      <div className={styles.header}>
        <h1>Booked!</h1>
        <div clasName={styles.ConfirmBookingList}>
          <Card className={styles.confirmed}>
            <h2>Booking Details</h2>
            <p>Service: Eye Exam</p>
            <p>Date: June 14th</p>
            <p>Time: 4pm</p>
            <p>Clinic: Bailey Nelson (W 4th Ave)</p>
            <p>Add to your calander</p>
          </Card>
          <div className={styles.amount}>
            <div className={styles.column}>
              <h3>Amount Due</h3>
              <h2>$140.00</h2>
            </div>
            <div className={styles.column}>
              <h3>Policy Number</h3>
              <h2 className={styles.purple}>77402</h2>
            </div>
          </div>
        </div>

        <Button
          onClick={submit}
          variant="contained"
          fullWidth={true}
          disabled={submitted}
          className={styles.button}
        >
          Submit{" "}
        </Button>

        {submitted && (
          <h4 className={styles.thankyou}>
            Thank you! Your claim has been approved!
          </h4>
        )}
      </div>
    </div>
  );
}

export default ConfirmBooking;
