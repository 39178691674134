import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#8c52ff",
    },
    secondary: {
      main: "#fff",
    },
    success: {
      main: "#00AA51",
    },
    text: {
      primary: "#5e5e5e",
      secondary: "#284b54",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },

    error: {
      main: "#ab0000",
    },
    info: {
      main: "#284b54",
    },
  },
});
