import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import { matchRoutes, useLocation } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import Benefits from "../Benefits";
import Service from "../Service";
import Booking from "../Booking";
import ConfirmBooking from "../ConfirmBooking";
import Login from "../Login";

function Main() {
  const location = useLocation();
  return (
    <div className="main">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/benefits" element={<Benefits />} />
        <Route path="/services" element={<Service />} />
        <Route path="/bookings" element={<Booking />} />
        <Route path="/confirm" element={<ConfirmBooking />} />
      </Routes>
    </div>
  );
}

export default Main;
