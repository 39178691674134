import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styles from "./Login.module.scss";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/elly-b-white.png";

const user = {
  username: "conor@ellybenefits.com",
  password: "testing12",
};

function Login() {
  let navigate = useNavigate();
  const goToHome = () => {
    navigate("/benefits");
  };

  return (
    <div className={styles.login}>
      <img src={Logo} alt="Elly" className={styles.logo} />

      <Box
        component="form"
        noValidate
        autoComplete="off"
        className={styles.form}
      >
        <Input
          defaultValue=""
          placeholder="Username"
          value={user.username}
          className={styles.input}
        />
        <Input
          defaultValue=""
          placeholder="Password"
          value={user.password}
          className={styles.input}
          type="password"
        />
        <div className={styles.rememberMe}>
          <FormControlLabel
            control={<Checkbox defaultChecked color="secondary" />}
            label="Remember Me"
          />

          <a href="#">Forgot Password?</a>
        </div>
        <Button
          variant="contained"
          className={styles.button}
          onClick={goToHome}
        >
          Login
        </Button>
      </Box>

      <div className={styles.footer}>
        <p className={styles.createAccount}>Not a member yet?</p>
        <p className={styles.createAccountLink}>Create Account</p>
      </div>
    </div>
  );
}

export default Login;
